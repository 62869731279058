/**
 * vue2filters port
 * Converts a string into Capitalize
 * 
 * 'abc' => 'Abc'
 * 
 * @param {Object} options
 */

export const capitalize = (value, options = { onlyFirstLetter : false }) => {
  if (!value && value !== 0) return '';
  if(options.onlyFirstLetter) {
    return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
  } else {
    const values = value.toString().toLowerCase().split(' ');
    return values?.map((item: string) => item.charAt(0).toUpperCase() + item.slice(1)).join(' ');
  }
};

/**
 * vue2filters port
 * Truncate at the given || default length
 *
 * 'lorem ipsum dolor' => 'lorem ipsum dol...'
 */

export const truncate = (value, length) => {
  length = length || 15;
  if( !value || typeof value !== 'string' ) return '';
  if( value.length <= length) return value;
  return value.substring(0, length) + '...';
};

/**
 * replaces special HTML chars with number code representation
 * @param {*} str input string, for example that comes from
 * @returns decoded string
 */
export const parseHtmlEntities = (str) => {
  return (str && str.replace) ? str.replace(
    /&#([0-9]{1,3});/gi, 
    function (_match: unknown, numStr: string) {
      const num = parseInt(numStr, 10); // read num as normal number
      return String.fromCharCode(num);
    }
  ) : '';
};

export const toSnakeCase = (str) => str
  .replace(/([A-Z])/g, ' $1')
  .replace(/[^a-zA-Z0-9]+/g, ' ')
  .trim()
  .replace(/\s+/g, '_')
  .toLowerCase();

export default {
  capitalize,
  truncate,
  parseHtmlEntities,
};