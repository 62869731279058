import { updateQueue } from '@/subjects/modalQueue';
import { tGlobal as t } from '@/functions/translate';

export function setErrors(error, dispatch = true) {
  const errors = error.errors.map(err => ({
    title: err.message,
    message: t('please_contact_support_and_quote_the_trace_id_err_trace_id', { traceId: err?.trace_id || '' }),
  }));
  if (dispatch) {
    updateQueue(errors);
  }
  return errors;
}
