/* eslint-disable no-console */
import { translationsState } from '@/subjects/translations';
export const BASE_LOCALE = 'en-US';

interface TranslationOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
// Define a type for pluralization function based on quantity
// eslint-disable-next-line no-unused-vars
type PluralizationRule = (quantity: number) => number;

// Define a type for pluralization rules object
type PluralizationRules = { [key: string]: PluralizationRule };

const translationsCache: { [key: string]: { [key: string]: string } } = {};
const loadingTranslations: Set<string> = new Set();

export const loadTranslations = async (group: string, locale: string): Promise<void> => {
  locale = locale || translationsState.getValue().locale || BASE_LOCALE;
  const cacheKey = `${group}-${locale}`;
  // if translations are already loaded, return
  if (translationsCache[cacheKey]) {
    return;
  }
  // if it is loading at the moment, return
  if (loadingTranslations.has(cacheKey)) {
    return;
  }
  loadingTranslations.add(cacheKey);
  try {
    const translations = await import(`@/../translations/${group}/${locale}.json`);
    translationsCache[cacheKey] = translations;
    console.warn(`Loaded translations for group: ${group}, locale: ${locale}`, translationsCache);
  } catch (error) {
    console.error(`Failed to load translations for group: ${group}, locale: ${locale}`, error);
  } finally {
    loadingTranslations.delete(cacheKey);
  }
};

export const loadTranslationsForLocale = async (locale: string): Promise<void> => {
  await Promise.all([
    loadTranslations('global', locale),
    loadTranslations('fe-auth', locale),
    loadTranslations('fe-breadcrumbs', locale),
    loadTranslations('fe-header', locale),
    loadTranslations('fe-utils', locale),
    loadTranslations('fe-crm', locale),
    loadTranslations('fe-admin', locale),
    loadTranslations('fe-aiquery', locale),
  ]);
};

// Utility function to escape special characters in a string for use in a regular expression
const escapeRegExp = (string: string): string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

// Updated `tGeneric` function with fallback to global translations
const tGeneric = (group: string, key: string, options: TranslationOptions = {}): string => {
  if (key === undefined || key === null || key === '') {
    return '';
  }

  const locale = translationsState.getValue().locale || BASE_LOCALE;
  // just in case we load translations for the locale - it is cheap if they are preloaded.
  // loadTranslations(group, locale);
  // loadTranslations('global', locale); // Always load the global translations
  let translation = '';
  const keyString = key?.toString();

  try {
    translation = translationsCache[`${group}-${locale}`]?.[keyString]
      ?? translationsCache[`global-${locale}`]?.[keyString]
      ?? translationsCache[`${group}-${BASE_LOCALE}`]?.[keyString] 
      ?? translationsCache[`global-${BASE_LOCALE}`]?.[keyString];

    if (!translation) {
      console.warn(`${group}.${locale}. Translation not found: '${keyString}'`);
      translation = keyString;
    }
      
    // Check for pluralization in the translation string (using pipe `|`)
    const isPluralized = translation.includes('|');
    if (isPluralized) {
      const quantity = options?.quantity ?? options?.count ?? 0; // Default to 0 if no quantity is provided
      translation = handlePluralization(translation, quantity, locale);
    }

    // Replace interpolations in the translation string
    Object.keys(options).forEach((interpolationKey) => {
      const interpolationValue = options[interpolationKey];
      try {
        const escapedKey = escapeRegExp(interpolationKey);
        translation = translation.replace(new RegExp(`{${escapedKey}}`, 'g'), interpolationValue.toString());
      } catch (regexError) {
        console.error(`Translation ${group}. Failed to replace interpolation key: ${interpolationKey} with value ${interpolationValue} in translation: ${translation}`, regexError);
      }
    });

    // Check for any remaining interpolation keys
    const remainingKeys = translation?.match(/{[^}]+}/g);
    if (remainingKeys) {
      console.error(`Translation. ${group}. Interpolation values not found for keys: ${remainingKeys.join(', ')}`);
    }
  } catch (error) {
    console.error(`Error processing translation for key: ${group}.${key}`, error);
  }

  return translation || keyString;
};

// Define the pluralization rules as an object
const pluralRules: PluralizationRules = {
  en: (quantity) => quantity === 1 ? 0 : 1, // latin languages defaulted to english rules
  ja: () => 0, // Japanese (no plural distinction)
  pl: (quantity) => {
    if (quantity === 1) return 0;
    if (quantity > 1 && quantity < 5) return 1;
    return 2; // Polish plural forms (few, many)
  },
  zh: () => 0, // Chinese (Simplified) (no plural distinction)
};

// Function to handle pluralization using pipe `|`
const handlePluralization = (translation: string, quantity: number, locale: string): string => {
  // Extract language code from the full locale (e.g., "it-IT" becomes "it")
  const lang = locale.split('-')[0]; // Get the language part of the 5-char code (e.g., 'it' from 'it-IT')
  const rule = pluralRules?.[locale] || pluralRules?.[lang] || pluralRules['en']; // Default to English rules if locale not found
  
  // Split the translation string by pipe `|`
  const forms = translation.split('|');
  const index = rule(quantity); // Get the pluralization form based on quantity
  
  // Return the correct plural form based on quantity
  return forms[index] || forms[1]; // Default to plural form if no specific index is found
};

// Export group-specific versions of the `t` function
export const t = (key: string, options: TranslationOptions = {}): string => tGeneric('global', key, options);
export const tGlobal = (key: string, options: TranslationOptions = {}): string => tGeneric('global', key, options);
export const tAdmin = (key: string, options: TranslationOptions = {}): string => tGeneric('fe-admin', key, options);
export const tBreadcrumbs = (key: string, options: TranslationOptions = {}): string => tGeneric('fe-breadcrumbs', key, options);
export const tCrm = (key: string, options: TranslationOptions = {}): string => tGeneric('fe-crm', key, options);
export const tUtils = (key: string, options: TranslationOptions = {}): string => tGeneric('fe-utils', key, options);
export const tHeader = (key: string, options: TranslationOptions = {}): string => tGeneric('fe-header', key, options);
export const tAiQuery = (key: string, options: TranslationOptions = {}): string => tGeneric('fe-aiquery', key, options);
export const tAuth = (key: string, options: TranslationOptions = {}): string => tGeneric('fe-auth', key, options);