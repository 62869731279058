// Anything exported from this file is importable by other in-browser modules.
// Functions
import abbr from './functions/abbr';
import address from './functions/address';
import auth from './functions/auth';
import {
  deepClone,
  isEqualObjects,
  getKeyByValue,
  searchObject
} from './functions/common';
import currency from './functions/currency';
import dates from './functions/datetime';
import { debounce } from './functions/debounce';
import escapeSpecialCharacters from './functions/escapeSpecialCharacters';
import { gqlRequest } from './functions/gqlRequest';
import { httpFileRequest } from './functions/httpFileRequest';
import { httpRequest } from './functions/httpRequest';
import pluralise from './functions/pluralise';
import { setErrors } from './functions/setErrors';
import storage from './functions/manageStorage';
import { t, tGlobal, tUtils, tAdmin, tAiQuery, tAuth, tCrm, tHeader, tBreadcrumbs,
  loadTranslations, loadTranslationsForLocale } from './functions/translate';
import { uuid } from './functions/uuid';
import strings from './functions/strings';
import breakpoints from './functions/breakpoints';
import { fileUpload } from './functions/fileUpload';

// Subjects
import { alertsState, setAlerts, removeAlertByIndex } from './subjects/alerts';
import { breadcrumbsState, updateBreadcrumb } from './subjects/breadcrumbs';
import { currencyState, getCurrency } from './subjects/currency';
import { customerModeState, toggleCustomerMode } from './subjects/customerMode';
import { mailState, mailThreadsState, getAccountInfo, getMail, sendMail, getThreadsSummary, getThreads, getThread, getMailAttachment } from './subjects/email';
import { mailComposeState, updateCompose } from './subjects/emailCompose';
import { modalQueueState, updateQueue, popMessageFromStack } from './subjects/modalQueue';
import { notificationDashboardState, updateWidgetToAdd } from './subjects/notificationDashboardState';
import { permissionsState, getPermissions, featureFlagsState } from './subjects/permissions';
import { searchBarState, showSearchBar, hideSearchBar, setSearchBarType } from './subjects/searchBar';
import { tenantState, getTenantDetails } from './subjects/tenant';
import { translationsState, getCalendarLocale, getTenantLanguages, putTenantLanguages, putSubjectDefaultLanguage,
  getSubjectLanguages, getSubjectDefaultLanguage } from './subjects/translations';
import { isLoggedInState, userDetailsState, getUserDetails } from './subjects/userDetails';
import { widgetState, getWidget } from './subjects/widget';

import * as ReportTypes from '@/consts/reportTypes';
import { 
  exportMatrix, exportVariance, exportFullPicture, exportSnapshot, exportSnapshotIframe,
  exportQueryBuilder, exportSalesVsGaps, exportReport, exportSnapshotByAnalysisField } from '@/api/export';
import { getFileUrl } from '@/api/download';

window['FE_UTILS_VERSION'] = process.env.FE_UTILS_VERSION;

export {
  // RxJS Subjects
  alertsState,
  breadcrumbsState,
  currencyState,
  customerModeState,
  isLoggedInState,
  mailState,
  mailThreadsState,
  mailComposeState,
  modalQueueState,
  notificationDashboardState,
  permissionsState,
  searchBarState,
  tenantState,
  translationsState,
  userDetailsState,
  featureFlagsState,
  widgetState,

  // RxJS Functions
  getAccountInfo,
  getCurrency,
  getMail,
  getMailAttachment,
  getPermissions,
  getTenantDetails,
  getThreadsSummary,
  getThreads,
  getThread,
  getUserDetails,
  hideSearchBar,
  popMessageFromStack,
  removeAlertByIndex,
  sendMail,
  setAlerts,
  setSearchBarType,
  showSearchBar,
  toggleCustomerMode,
  updateBreadcrumb,
  updateCompose,
  updateQueue,
  updateWidgetToAdd,
  getWidget,

  // Util Functions
  abbr,
  address,
  auth,
  currency,
  dates,
  debounce,
  deepClone,
  escapeSpecialCharacters,
  gqlRequest,
  httpFileRequest,
  httpRequest,
  isEqualObjects,
  getKeyByValue,
  pluralise,
  searchObject,
  setErrors,
  storage,
  t, tGlobal, tUtils, tAdmin, tAiQuery, tAuth, tCrm, tHeader, tBreadcrumbs, loadTranslations, loadTranslationsForLocale,
  getTenantLanguages, putTenantLanguages, getSubjectLanguages, putSubjectDefaultLanguage, getCalendarLocale, getSubjectDefaultLanguage,
  uuid,
  strings,
  breakpoints,
  fileUpload,

  // Export Functions
  exportMatrix,
  exportVariance,
  exportFullPicture,
  exportQueryBuilder,
  exportSalesVsGaps,
  exportReport,
  exportSnapshot,
  exportSnapshotIframe,
  exportSnapshotByAnalysisField,
  getFileUrl,

  // Report Type Constants as Namespace
  ReportTypes,
};